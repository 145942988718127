import React from "react";
import "./App.less";
import { FancyImageSlider } from "./FancyImageSlider";

export default function App() {
  return (
    <div className="fancy-portfolio-app">
      <FancyImageSlider />
    </div>
  );
}
